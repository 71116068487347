import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

class TestImagePage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Privacy Policy | Quandary Consulting Group"
          description="Expand your bottom line. Exceed customer expectations. Experience efficiency like never before.Choose Quandary to guide your workflow optimization."
        />
        <div className="container my-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/1st-image.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/2nd-image.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/3rd-image.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/4th-image.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/5-image.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/6-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/7-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/8-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/9-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/10-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/11-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/12-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/13-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />{" "}
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/14-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/15-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/16-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
              <StaticImage
                placeholder="blurred"
                className="rounded w-100"
                src="../images/new-images/17-img.webp"
                alt="Quandary Peak, Colorado 14,265 feet"
                style={{ height: "25rem" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TestImagePage
